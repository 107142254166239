#root {
	width: 100vw;
	height: 100vh;
}

.spinner-container {
	display: block;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.btn-login {
	width: 100%;
}
